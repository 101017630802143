<template>
  <el-card class="box-card">
    <h2>修改密码</h2>
    <el-form :model="form" :rules="rules" ref="form" label-width="100px" class="form">
      <el-form-item label="当前密码" prop="currentPassword">
        <el-input type="password" v-model="form.currentPassword" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="newPassword">
        <el-input type="password" v-model="form.newPassword" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="确认新密码" prop="confirmPassword">
        <el-input type="password" v-model="form.confirmPassword" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" :loading="subLoding" @click="submitForm('form')">提交</el-button>
        <el-button @click="resetForm('form')">重置</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
import {resetPwd } from "@/api/webList";
import {ElMessage,ElMessageBox} from "element-plus";

export default {
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.form.newPassword !== '') {
          this.$refs.form.validateField('confirmPassword');
        }
        callback();
      }
    };

    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.form.newPassword) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };

    return {
      form: {
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      subLoading:false,
      rules: {
        currentPassword: [
          { required: true, message: '请输入当前密码', trigger: 'blur' }
        ],
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { validator: validatePass, trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, message: '请确认新密码', trigger: 'blur' },
          { validator: validatePass2, trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
     submitForm(formName) {
       this.subLoading = true;
       this.$refs[formName].validate(async(valid) => {
        if (valid) {
          // 这里可以添加提交表单的逻辑，例如调用API
          var params={
            current_password:this.form.currentPassword,
            new_password:this.form.newPassword
          }
          var res = await resetPwd(params);

          if(res.code === 200){
            this.resetForm('form');
              ElMessageBox.alert(
                  res.message,
                  '密码修改成功提醒',
                  {
                    confirmButtonText: '确认'
                  }
              ).then( () => {

              }).catch(() => {

              })
            }

          this.subLoading = false;
        } else {
          this.subLoading = false;
          console.log('提交失败');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>

<style scoped>
.box-card {
  max-width: 50%;
  margin: 50px auto;
}
.form {
  margin-top: 20px;
}
</style>
